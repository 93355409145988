.benefits {
	&__title {
		font-size: 2.75rem;
		padding-bottom: 1rem;
	}

	&__item:not(&__item--counter) {
		padding-top: 16px;
	}

	&__item {
		&-inner {
			display: flex;
		}

		&-name {
			font-size: 1.5rem;
		}

		&-image {
			max-width: 90px;
			width: 100%;
		}

		&-content {
			padding-left: 0.5rem;
			padding-top: 0.5rem;
			padding-right: 2rem;
		}

		&--counter {
			text-align: center;

			.benefits__item-inner {
				justify-content: center;
			}

			@media (max-width:767px) {
				width: 100% !important;
				flex: 0 0 100% !important;
				max-width: 100% !important;
			}
		}

		&-counter {
			text-align: center;

			&-days {
				display: inline-flex;
				justify-content: center;
				position: relative;

				&:before {
					content: "";
					height: 3px;
					background-color: #000;
					position: absolute;
					top: 36px;
					left: 0;
					right: 0;
				}

				&-letter {
					padding: 2.3rem 1rem;
					background: #ff7900;
					margin: 0 2px;
					border-radius: 10px;
					color: #000;
					font-size: 3rem;
					line-height: 0;
				}
			}
		}

		@media (max-width:767px) {
			padding: 20px 0;
		}
	}

	@media (max-width:767px) {
		&__title {
			font-size: 24px;
		}
	}
}