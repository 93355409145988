.section-prizes {
	&__title {
		font-size: 2.75rem;
		padding-bottom: 0.5rem;
	}

	.prize-item {
		padding: 10px;

		&__inner {
			position: relative;

			.form-group__info {
				width: 1.5rem;
				height: 1.5rem;
				position: absolute;
				top: 10px;
				right: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 1rem;
				cursor: pointer;
				flex-shrink:0;
		
				&:hover .tooltip {
					display: block;
					visibility: visible !important;
					opacity: 1 !important;
				}
		
				img {
					max-width: 4px;
					height: 16px;
					width: 100%;
					padding: 0;
				}
		
				.tooltip {
					position: absolute;
					bottom: calc(100% + 10px);
					max-width: 290px;
					width: max-content;
					background-color: #fff;
					color: #000;
					padding: 0.75rem 1rem;
					display: none;
		
					@media(max-width: 1600px) {
						right: -1rem;
					}
		
					@media(max-width: 440px) {
						max-width: 220px;
					}
				}
			}
		}

		&__description {
			font-size: 1.25rem;

			@media(max-width: 767px) {
				font-size: 14px;
			}

			@media(max-width: 640px) {
				font-size: 1.5rem;
			}

			@media(max-width: 540px) {
				font-size: 1rem;
			}
		}

		&--mobile-full {
			@media(max-width:767px) and (min-width:370px) {
				.prize-item__inner {
					flex-direction: row !important;
					align-items: center;
				}

				.prize-item__mobile-wrap {
					width: 50%;
					flex-shrink: 0;
				}
			}
		}
	}

	.prize-item--small-prize {
		.prize-item__inner {
			padding: 1rem;

			@media(max-width: 767px) {
				padding: 3rem 1rem;
			}

			@media(max-width: 640px) {
				padding: 1rem;
			}

			h4 {
				@media(max-width: 767px) {
					font-size: 4.25rem !important;
				}

				@media(max-width: 640px) {
					font-size: 2.25rem !important;
				}

				@media(max-width: 540px) {
					font-size: 1.5rem !important;
				}
			}

		}

		img {
			max-width: 97px;
			padding-top: 1.25rem;
			padding-bottom: 1.25rem;

			@media(max-width: 767px) {
				padding-top: 0.5rem;
			}

			@media(max-width: 540px) {
				max-width: 80px;
			}
		}
	}

	@media (max-width:767px) {

		&__title {
			font-size: 24px;
		}
	}
}