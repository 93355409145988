.section-hero {
	.hero__header {
		font-size: 50px;
		line-height: 1;
	}

	.hero__description {
		font-size: 24px;
	}

	.image-flame {
		max-width: 200px;
		width: 100%;
	}

	.hero__images {
		align-items: baseline;
		justify-content: center;
	}

	.hero__button {
		font-size: 1.5rem;
		padding: .5rem 1.5rem;
	}

	.hero__images-container .hero__button {
		display: none;
	}

	@media(max-width: 1200px) {
		.hero__header {
			font-size: 3.5rem;
			padding-right: 1rem;
		}

		.hero__description {
			font-size: 1.5rem;
		}

		.image-fortune-wheel {
			max-width: 220px;
		}

		.image-flame {
			max-width: 170px;
		}

		.hero__button {
			font-size: 1.5rem;
			padding: .5rem 1.25rem;
		}
	}

	@media(max-width: 991px) {
		.hero__description {
			font-size: 1.25rem;
		}

		.image-fortune-wheel {
			max-width: 180px;
		}

		.image-flame {
			max-width: 130px;
		}

		.hero__button {
			font-size: 1.25rem;
		}
	}

	@media(max-width: 767px) {
		.hero__info .hero__button {
			display: none;
		}

		.hero__description {
			font-size: 14px;
		}

		.hero__header {
			font-size: 24px;
		}

		.hero__images-container .hero__button {
			display: block;
			width: 100%;
		}

		.hero__images {
			padding-top: 1.5rem;
		}
	}
}