@import './hero.scss';
@import './prizes.scss';
@import './main_form.scss';
@import './winner_form.scss';
@import './campaign_over.scss';
@import './redirect_area.scss';
@import './benefits.scss';


@font-face {
	font-family: helveticaneue;
	src: url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/EOT/HelvNeue55_W1G.eot);
	src: url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/EOT/HelvNeue55_W1G.eot?#iefix)format("embedded-opentype"), url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/WOFF2/HelvNeue55_W1G.woff2)format("woff2"), url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/SVG/HelvNeue55_W1G.svg#svgFontName)format("svg");
	font-weight: 400;
	font-display: swap
}

@font-face {
	font-family: helveticaneue;
	src: url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/EOT/HelvNeue75_W1G.eot);
	src: url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/EOT/HelvNeue75_W1G.eot?#iefix)format("embedded-opentype"), url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/WOFF2/HelvNeue75_W1G.woff2)format("woff2"), url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/WOFF/HelvNeue75_W1G.woff)format("woff"), url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/TTF/HelvNeue75_W1G.ttf)format("truetype"), url(https://d2nj8yhmfi4dlg.cloudfront.net/fonts/SVG/HelvNeue75_W1G.svg#svgFontName)format("svg");
	font-weight: 700;
	font-display: swap
}

body {
	margin: 0;
}

*,
*::before,
*::after {
	box-sizing: border-box
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: .5rem
}

p {
	margin-top: 0;
	margin-bottom: 1rem
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0
}

b,
strong {
	font-weight: bolder
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline
}

sup {
	top: -.5em
}

a {
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects
}

a:hover {
	text-decoration: underline
}

a:not([href]):not([tabindex]) {
	color: inherit;
	text-decoration: none
}

img {
	vertical-align: middle;
	border-style: none
}

svg:not(:root) {
	overflow: hidden
}

label {
	display: inline-block;
	/* margin-bottom: .5rem */
}

button {
	border-radius: 0
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit
}

button,
input {
	overflow: visible
}

button,
select {
	text-transform: none
}

button,
html [type=button],
[type=reset],
[type=submit] {
	appearance: button;
	-webkit-appearance: button;
}

input[type=radio],
input[type=checkbox] {
	box-sizing: border-box;
	padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: .5rem;
	font-family: inherit;
	font-weight: 900;
	line-height: 1.2;
	color: inherit
}

h1,
.h1 {
	font-size: 3rem
}

h2,
.h2 {
	font-size: 2.5rem
}

h3,
.h3 {
	font-size: 2.25rem
}

h4,
.h4 {
	font-size: 2rem
}

h5,
.h5 {
	font-size: 1.75rem
}

h6,
.h6 {
	font-size: 1.5rem
}

.lead {
	font-size: 1.25rem;
	font-weight: 400
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 2px solid rgba(0, 0, 0, .1)
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.container {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto
}

@media(min-width: 576px) {
	.container {
		max-width: 540px
	}
}

@media(min-width: 768px) {
	.container {
		max-width: 720px
	}
}

@media(min-width: 992px) {
	.container {
		max-width: 960px
	}
}

@media(min-width: 1200px) {
	.container {
		max-width: 1300px
	}
}

.container-fluid {
	width: 100%;
	padding-right: 1rem;
	padding-left: 1rem;
	margin-right: auto;
	margin-left: auto
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -1rem;
	margin-left: -1rem
}

.no-gutters {
	margin-right: 0;
	margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
	padding-right: 0;
	padding-left: 0
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 1rem;
	padding-left: 1rem
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%
}

.col-2 {
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%
}

.col-4 {
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%
}

.col-7 {
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%
}

.col-8 {
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%
}

.col-9 {
	flex: 0 0 75%;
	max-width: 75%
}

.col-12 {
	flex: 0 0 100%;
	max-width: 100%
}

.order-0 {
	order: 0
}

.order-1 {
	order: 1
}

.order-2 {
	order: 2
}

@media(min-width: 535px) {
	.col-xs-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

@media(min-width: 576px) {
	.col-sm-3 {
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-sm-4 {
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%
	}

	.col-sm-6 {
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-sm-8 {
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%
	}

	.col-sm-12 {
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-sm-1 {
		order: 1
	}

	.order-sm-2 {
		order: 2
	}
}

@media(min-width: 768px) {
	.col-md-2 {
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%
	}

	.col-md-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}

	.col-md-4 {
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%
	}

	.col-md-5 {
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%
	}

	.col-md-6 {
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-md-7 {
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%
	}

	.col-md-8 {
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%
	}

	.col-md-9 {
		flex: 0 0 75%;
		max-width: 75%
	}

	.col-md-12 {
		flex: 0 0 100%;
		max-width: 100%
	}

	.order-md-0 {
		order: 0
	}

	.order-md-1 {
		order: 1
	}

	.order-md-2 {
		order: 2
	}

	.order-md-3 {
		order: 3
	}

}

@media (min-width:1024px) {

	.col-lg-2 {
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%
	}

	.col-lg-3 {
		flex: 0 0 25%;
		max-width: 25%
	}

	.col-lg-4 {
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%
	}

	.col-lg-6 {
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-lg-9 {
		flex: 0 0 75%;
		max-width: 75%
	}
}

@media(min-width: 1200px) {
	.col-xl-3 {
		flex: 0 0 20%;
		max-width: 20%;
	}

	.col-xl-4 {
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%
	}

	.col-xl-5 {
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%
	}

	.col-xl-6 {
		flex: 0 0 50%;
		max-width: 50%
	}

	.col-xl-7 {
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%
	}
}

.btn {
	display: inline-block;
	font-weight: 900;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	user-select: none;
	border: 2px solid transparent;
	padding: .75rem 1.5rem;
	font-size: 1.25rem;
	line-height: 1.2;
	border-radius: 0;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

	@media(max-width: 480px) {
		font-size: 1rem;
		padding: .5rem 1rem;
	}
}

.btn:hover,
.btn:focus {
	text-decoration: none
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer
}

.btn-primary {
	color: #fff !important;
	background-color: #ff7900;
	border-color: #ff7900;
	padding: .75rem 1.25rem;
}

.btn-primary.active,
.btn-primary.focus,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
	border-color: #ffffff !important;
	background-color: transparent;
}

.btn-secondary {
	color: #fff;
	background-color: #000;
	border-color: #000;
}

.btn-transparent {
	color: #fff;
	background-color: transparent;
	border: none;
	border-bottom: 2px solid #ffffff;
	padding: 0;
	cursor: pointer;
	height: fit-content;

	@media(max-width: 480px) {
		font-size: 14px;
	}

	@media (max-width: 360px) {
		margin-top: 2rem;
	}
}

.btn-light {
	color: #333;
	background-color: #f5f5f5;
	border-color: #f5f5f5
}

.btn-outline-light {
	color: #f5f5f5;
	background-color: transparent;
	background-image: none;
	border-color: #f5f5f5
}

.btn-outline-dark {
	color: #333;
	background-color: transparent;
	background-image: none;
	border-color: #333
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #333;
	border-color: #333
}

.btn-lg,
.btn-group-lg>.btn {
	padding: 0 3rem;
	font-size: 1rem;
	line-height: 46px;
	border-radius: 0
}

.btn-sm,
.btn-group-sm>.btn {
	padding: 0 1rem;
	font-size: .75rem;
	line-height: 28px;
	border-radius: 0
}

.border-0 {
	border: 0 !important
}

.clearfix::after {
	display: block;
	clear: both;
	content: ""
}

.d-none {
	display: none !important
}

.d-block {
	display: block !important
}

.d-flex {
	display: flex !important
}

.d-inline-flex {
	display: inline-flex !important
}

@media(min-width: 576px) {
	.d-sm-flex {
		display: flex !important;
	}
}

@media(min-width: 768px) {
	.d-md-none {
		display: none !important;
	}

	.d-md-inline-block {
		display: inline-block !important;
	}

	.d-md-block {
		display: block !important;
	}

	.d-md-flex {
		display: flex !important;
	}
}

@media(min-width: 992px) {
	.d-lg-none {
		display: none !important;
	}

	.d-lg-block {
		display: block !important;
	}

	.d-lg-flex {
		display: flex !important
	}
}

@media(min-width: 1200px) {
	.d-xl-none {
		display: none !important;
	}

	.d-xl-block {
		display: block !important
	}

	.d-xl-flex {
		display: flex !important
	}
}

.flex-column {
	flex-direction: column !important;
}

.justify-content-center {
	justify-content: center !important
}

.justify-content-between {
	justify-content: space-between !important
}

.align-items-end {
	align-items: flex-end !important
}

.align-items-center {
	align-items: center !important
}

.align-self-start {
	align-self: flex-start !important
}

.align-self-end {
	align-self: flex-end !important
}

.align-self-center {
	align-self: center !important
}

@media(min-width: 768px) {
	.align-self-md-start {
		align-self: flex-start !important
	}

	.mobile-only {
		display: none !important;
	}
}

@media(min-width: 992px) {
	.align-self-lg-center {
		align-self: center !important
	}
}

@media(min-width: 1200px) {
	.align-self-xl-end {
		align-self: flex-end !important
	}
}

@media(max-width: 768px) {
	.desktop-only {
		display: none !important;
	}

	.mobile-only {
		display: block !important;
	}
}

.float-left {
	float: left !important
}

.float-right {
	float: right !important
}

.position-relative {
	position: relative !important
}

.position-absolute {
	position: absolute !important
}

.w-50 {
	width: 50% !important
}

.w-100 {
	width: 100% !important
}

.h-50 {
	height: 50% !important
}

.h-100 {
	height: 100% !important
}

.m-0 {
	margin: 0 !important
}

.mt-0,
.my-0 {
	margin-top: 0 !important
}

.mr-0,
.mx-0 {
	margin-right: 0 !important
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important
}

.mt-1,
.my-1 {
	margin-top: .25rem !important
}

.mr-1,
.mx-1 {
	margin-right: .25rem !important
}

.ml-1,
.mx-1 {
	margin-left: .25rem !important
}

.mt-2,
.my-2 {
	margin-top: .5rem !important
}

.mr-2,
.mx-2 {
	margin-right: .5rem !important
}

.mb-2,
.my-2 {
	margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
	margin-left: .5rem !important
}

.mt-3,
.my-3 {
	margin-top: 1rem !important
}

.mr-3,
.mx-3 {
	margin-right: 1rem !important
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
	margin-left: 1rem !important
}

.mt-4,
.my-4 {
	margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
	margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
	margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
	margin-left: 1.5rem !important
}

.mt-5,
.my-5 {
	margin-top: 3rem !important
}

.mr-5,
.mx-5 {
	margin-right: 3rem !important
}

.mb-5,
.my-5 {
	margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
	margin-left: 3rem !important
}

.p-0 {
	padding: 0 !important
}

.pt-0,
.py-0 {
	padding-top: 0 !important
}

.pr-0,
.px-0 {
	padding-right: 0 !important
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important
}

.pl-0,
.px-0 {
	padding-left: 0 !important
}

.pt-1,
.py-1 {
	padding-top: .25rem !important
}

.pb-1,
.py-1 {
	padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
	padding-left: .25rem !important
}

.pt-2,
.py-2 {
	padding-top: .5rem !important
}

.pr-2,
.px-2 {
	padding-right: .5rem !important
}

.pb-2,
.py-2 {
	padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
	padding-left: .5rem !important
}

.p-3 {
	padding: 1rem !important
}

.pt-3,
.py-3 {
	padding-top: 1rem !important
}

.pr-3,
.px-3 {
	padding-right: 1rem !important
}

.pb-3,
.py-3 {
	padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
	padding-left: 1rem !important
}

.p-4 {
	padding: 1.5rem !important
}

.pt-4,
.py-4 {
	padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
	padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
	padding-left: 1.5rem !important
}

.pt-5,
.py-5 {
	padding-top: 3rem !important
}

.pr-5,
.px-5 {
	padding-right: 3rem !important
}

.pb-5,
.py-5 {
	padding-bottom: 3rem !important
}

.mr-auto,
.mx-auto {
	margin-right: auto !important
}

.ml-auto,
.mx-auto {
	margin-left: auto !important
}

@media(min-width: 576px) {

	.mb-sm-3,
	.my-sm-3 {
		margin-bottom: 1rem !important
	}
}

@media(min-width: 768px) {

	.mt-md-0,
	.my-md-0 {
		margin-top: 0 !important
	}

	.mb-md-0,
	.my-md-0 {
		margin-bottom: 0 !important
	}

	.mt-md-2,
	.my-md-2 {
		margin-top: .5rem !important
	}

	.mb-md-2,
	.my-md-2 {
		margin-bottom: .5rem !important
	}

	.mt-md-3,
	.my-md-3 {
		margin-top: 1rem !important
	}

	.mt-md-4,
	.my-md-4 {
		margin-top: 1.5rem !important
	}

	.mt-md-5,
	.my-md-5 {
		margin-top: 3rem !important
	}

	.pt-md-0,
	.py-md-0 {
		padding-top: 0 !important
	}

	.pr-md-3,
	.px-md-3 {
		padding-right: 1rem !important
	}

	.pl-md-3,
	.px-md-3 {
		padding-left: 1rem !important
	}

	.pr-md-4,
	.px-md-4 {
		padding-right: 1.5rem !important
	}

	.pt-md-5,
	.py-md-5 {
		padding-top: 3rem !important
	}

	.pr-md-5,
	.px-md-5 {
		padding-right: 3rem !important
	}

	.pl-md-5,
	.px-md-5 {
		padding-left: 3rem !important
	}
}

@media(min-width: 992px) {

	.mt-lg-0,
	.my-lg-0 {
		margin-top: 0 !important
	}

	.mb-lg-2,
	.my-lg-2 {
		margin-bottom: .5rem !important
	}

	.mt-lg-4,
	.my-lg-4 {
		margin-top: 1.5rem !important
	}

	.mt-lg-5,
	.my-lg-5 {
		margin-top: 3rem !important
	}
}

@media(min-width: 1200px) {

	.mt-xl-4,
	.my-xl-4 {
		margin-top: 1.5rem !important
	}

	.pt-xl-0,
	.py-xl-0 {
		padding-top: 0 !important
	}

	.pb-xl-0,
	.py-xl-0 {
		padding-bottom: 0 !important
	}
}

.text-center {
	text-align: center !important
}

@media(min-width: 768px) {
	.text-md-center {
		text-align: center !important
	}
}

.font-weight-normal {
	font-weight: 400 !important
}

.font-weight-bold {
	font-weight: 900 !important
}

.text-white {
	color: #fff !important
}

.text-primary {
	color: #ff7900 !important
}

.text-danger {
	color: #cd3c14 !important
}

.text-muted {
	color: #999 !important
}

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}


a {
	color: #ff7900;
	text-decoration: none
}

a:hover,
a:active,
a:focus {
	color: #ffffff;
	text-decoration: none;
	box-shadow: none;
	outline: none;
	cursor: pointer
}

button:hover,
button:active,
button:focus,
.btn:hover,
.btn:active,
.btn:focus,
input:hover,
input:active,
input:focus {
	outline: none !important
}

.container {
	max-width: 1320px
}

a {
	color: #ffffff
}

a:hover {
	color: #ffffff;
	text-decoration: none;
}

a.active {
	color: #ff7900
}

.cursor-pointer {
	cursor: pointer
}

.text-orange {
	color: #ff7900 !important;
}

.text-black {
	color: #000
}

.text-white {
	color: #fff
}

.text-grey-dark {
	color: #595959
}

.text-grey-d3 {
	color: #999
}

.background-yellow {
	background-color: #ffd200
}

.background-orange {
	background-color: #ff7900 !important;
}

.background-grey {
	background-color: #333;
}

h1,
h2,
h3 {
	letter-spacing: -1px;
	line-height: 110%
}

h1,
.h1 {
	font-size: 2.35rem
}

@media(min-width: 576px) {

	h1,
	.h1 {
		font-size: 3rem
	}
}

h2,
.h2 {
	font-size: 2.15rem
}

@media(min-width: 576px) {

	h2,
	.h2 {
		font-size: 2.5rem
	}
}

h3,
.h3 {
	font-size: 1.95rem
}

@media(min-width: 576px) {

	h3,
	.h3 {
		font-size: 2.25rem
	}
}

h4,
.h4 {
	font-size: 1.75rem
}

@media(min-width: 576px) {

	h4,
	.h4 {
		font-size: 2rem
	}
}

h5,
.h5 {
	font-size: 1.5rem
}

@media(min-width: 576px) {

	h5,
	.h5 {
		font-size: 1.75rem
	}
}

h6,
.h6 {
	font-size: 1.25rem
}

@media(min-width: 576px) {

	h6,
	.h6 {
		font-size: 1.5rem
	}
}

p:last-child {
	margin-bottom: 0
}

/*OMNI STYLES*/

#omni-container {
	font-family: helveticaneue, Arial, sans-serif;
	color: #ffffff;
	background-color: #000000;
	position: relative;
}

/* form controls START*/
.section-form {
	background-color: #000000;
	align-items: center;
	overflow: auto;
	display: none;
	transition: all 0.5s ease-in-out;

	&:not(.section-form--winner).active-section {
		display: flex;
	}

	&.section-form--winner.active-section {
		display: block;
	}

	.section-form__header {
		padding-bottom: 1rem;
		font-size: 2.75rem;
	}

	.showMore-btn {
		cursor: pointer;
		padding-left: 2rem;
	}

	.form-control__raffle-code {
		text-transform: uppercase;
	}

	.main-error-container,
	.message-already-played,
	.succes-message-container,
	.message-already-winner {
		padding: .75rem 1rem;
	}

	.main-error-container {
		background-color: #cd3c14;
	}

	.message-already-played,
	.message-already-winner {
		background-color: #ffffff;
		color: #000000;
	}

	.succes-message-container {
		text-align: center;
		font-size: 2rem;
	}

	.message-already-winner .btn-transparent {
		font-size: 1rem !important;
		font-weight: 700;
	}

	.message-already-winner .btn-transparent:hover {
		background-color: transparent !important;
		background: transparent !important;
	}

	.section-form__close {
		position: absolute;
		width: 1.5rem;
		height: 1.5rem;
		top: -2rem;
		right: -.5rem;
		cursor: pointer;

		@media(max-width: 768px) {
			right: 1rem;
		}

		&:before,
		&:after {
			content: "";
			position: absolute;
			width: 2px;
			height: 1.5rem;
			background-color: #ff7900;
			display: block;
			left: 15px;

			@media(min-width: 768px) {
				left: 10px
			}
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	// @media(min-width:1024px) {
	//     position: fixed;
	//     top: 0;
	//     bottom: 0;
	//     left: 0;
	//     right: 0;
	// }
	@media (max-width:767px) {
		.section-form__header {
			font-size: 24px;
		}

		.showMore-btn,
		.form__info {
			font-size: 14px;
		}
	}

	@media(max-width: 360px) {
		.section-form__buttons {
			flex-direction: column;
		}
	}
}

.form__inner {
	max-width: 676px;
	width: 100%;
	margin: 0 auto;
}

.form__info {
	font-size: 1.25rem;
}

.form-group--input.form-group--error {
	input {
		-moz-box-shadow: 0 0 0 2px #cd3c14 !important;
		-webkit-box-shadow: 0 0 0 2px #cd3c14 !important;
		box-shadow: 0 0 0 2px #cd3c14 !important;
	}
}

.form-group--voucher-pin input {
	text-transform: uppercase;
}

.form-group--input {
	display: flex;
	align-items: center;
	justify-self: start;

	.form-group__info {
		width: 1.5rem;
		height: 1.5rem;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 1rem;
		cursor: pointer;
		flex-shrink: 0;

		&:hover .tooltip {
			display: block;
			visibility: visible !important;
			opacity: 1 !important;
		}

		img {
			max-width: 4px;
			height: 16px;
		}

		.tooltip {
			position: absolute;
			bottom: calc(100% + 10px);
			max-width: 290px;
			width: max-content;
			background-color: #fff;
			color: #000;
			padding: 0.75rem 1rem;
			display: none;

			@media(max-width: 540px) {
				right: -1rem;
			}

			@media(max-width: 440px) {
				max-width: 220px;
			}
		}
	}

	label {
		font-size: 1.25rem;
		min-width: 6.25rem;

		@media(max-width: 480px) {
			font-size: 1rem;
			min-width: 5rem;
		}
	}

	input {
		background-color: transparent;
		border: none;
		padding: 1rem;
		font-size: 16px;
		max-width: 12rem;
		color: #ffffff;
		-moz-box-shadow: 0 0 0 2px #ffffff !important;
		-webkit-box-shadow: 0 0 0 2px #ffffff !important;
		box-shadow: 0 0 0 2px #ffffff !important;
		appearance: none;
		-webkit-appearance: none;
		border-radius: 0;
		transition: box-shadow .5s ease-in-out;

		@media screen and (-webkit-min-device-pixel-ratio:0) {
			input:focus {
				font-size: 16px;
			}
		}

		@media(max-width: 480px) {
			max-width: 12rem;
		}

		@media(max-width: 400px) {
			max-width: 10rem;
			padding: .75rem;
		}

		@media(max-width: 360px) {
			max-width: 8rem;
			font-size: 14px;
		}

		&:focus {
			-moz-box-shadow: 0 0 0 4px #ffffff !important;
			-webkit-box-shadow: 0 0 0 4px #ffffff !important;
			box-shadow: 0 0 0 4px #ffffff !important;
		}
	}

	&::placeholder {
		color: #ffffff;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: #ffffff;
	}

	&::-ms-input-placeholder {
		color: #ffffff;
	}
}

.form-group--checkbox.form-group--error {
	label:before {
		border-color: #cd3c14;
	}
}

.form-group--checkbox {
	label {
		padding-left: 2rem;
		position: relative;
		line-height: 1.5rem;
		cursor: pointer;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
		}

		&:before {
			width: 1.5rem;
			height: 1.5rem;
			border: 2px solid #ffffff;
		}

		&:after {
			background: center / cover url(../images/icon_checked.png) no-repeat;
			width: 1.5rem;
			height: 1.5rem;
			display: none;
		}
	}

	input {
		display: none !important;

		&:checked+label:after {
			display: block;
		}
	}

	@media (max-width:767px) {
		label {
			font-size: 14px;
		}
	}
}

/* form controls END*/


.wheel-spin-container {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #000000;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;

	video {
		max-width: 600px;
		width: 100%;
	}

	img {
		height: 35vh;
	}
}