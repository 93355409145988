.section-form--winner {
	display: block;

	.form__anouncement {
		font-size: 1.5rem;
		max-width: 650px;
		width: 100%;
		margin: 0 auto;
		padding-bottom: 4.25rem;
	}

	.form__anouncement-subtitle {
		font-size: 18px;
		max-width: 400px;
		margin: 20px auto 0;
	}

	.form__confetti img {
		width: 100%;
	}

	.form-group--input {
		label {
			min-width: 8.25rem;

			@media(max-width: 480px) {
				font-size: .8rem;
				min-width: 5.5rem;
			}

			@media(max-width: 360px) {
				font-size: .75rem;
				min-width: 83px;
				white-space: nowrap;
			}
		}

		input {
			max-width: 325px;

			@media(max-width: 480px) {
				font-size: 1rem;
				padding: 0.5rem;
				max-width: initial;
				width: 100%;
			}
		}
	}

	a.btn-primary {
		margin: 0 auto;
	}
}