.section-redirect {
	.btn {
		font-size: 1.5rem;
	}

	@media (max-width: 991px) {
		.btn {
			font-size: 1.25rem;
		}
	}

	@media (max-width: 767px) {
		.btn {
			width: 100%;
		}
	}
}